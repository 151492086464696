<!--index:Hi, 我的营地 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="yingdi-index" v-infinite-scroll="getUserDynamicList" :infinite-scroll-disabled=!loadMore
    :infinite-scroll-immediate=false :infinite-scroll-distance=30>
    <friend-news-item v-for="(item, index) in list" :key="'yingdi' + index" :item="item"
      :is-ta="isTa"></friend-news-item>
  </div>
</template>
<script>
import FriendNewsItem from '@/views/user/components/FriendNewsItem.vue'
import { articleApi, userApi, interactionApi } from '@/utils/api'
export default {
  name: 'index',
  components: { FriendNewsItem },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      isloading: false,
      currentPage: 1,
      loadMore: true,
      list: [],
      isTa: this.$route.query.id !== undefined && this.$route.query.id !== ''
    }
  },
  created () {
    this.getUserDynamicList()
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    getTypeDesc: function (item) {
      if (item.userDynamicType === 'PUBLISH_ARTICLE') {
        return this.$t('publishPost')
      } else if (item.userDynamicType === 'PUBLISH_ARTICLE_COMMENT') {
        return this.$t('publishComment')
      } else if (item.userDynamicType === 'LIKE_ARTICLE') {
        return this.$t('firePost')
      }
    },
    getUserDynamicList: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      userApi.getUserDynamicList({
        userIds: this.$route.query.id || this.$store.state.user.id,
        currentPage: this.currentPage,
        pageSize: 10
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200) {
          console.log('getUserDynamicList', resp)
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          if (resp.data.length === 0) {
            return
          }
          this.list = this.list.concat(resp.data)
          this.currentPage++

          const articles = resp.data.filter(item => item.article !== null)
          if (articles && articles.length > 0) {
            const idSet = new Set(articles.map(item => item.article.id))
            const articleIds = Array.from(idSet).join(',')
            this.getArticleInteractions(articleIds)
          }

          const articleComments = resp.data.filter(item => item.articleComment !== null)
          if (!articleComments || articleComments.length === 0) {
            return
          }
          const commentIdSet = new Set(articleComments.map(item => item.articleComment.id))
          const commentIds = Array.from(commentIdSet).join(',')
          this.getCommentInteractions(commentIds)
        }
      }).catch(err => {
        console.log('err', err)
        this.isloading = false
      })
    },
    /**
     *  文章互动数据
     * @param {文章 id} articleIds 用逗号分割
     */
    getArticleInteractions: function (articleIds) {
      if (!articleIds || articleIds.length === 0) {
        return
      }
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200) {
          // 为 articles 追加 互动数据
          const interactionMap = new Map(resp.data.map(interaction => [interaction.articleId, interaction]))
          this.list = this.list.map(item => {
            const interactionData = interactionMap.get(item.article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              item.article = {
                ...item.article,
                like: interactionData.like,
                likeInteractionRecordId: interactionData.likeInteractionRecordId,
                trample: interactionData.trample,
                trampleInteractionRecordId: interactionData.trampleInteractionRecordId,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            }
            return item
          })
        }
      })
    },
    getCommentInteractions: function (commentIds) {
      if (!commentIds || commentIds.length === 0) {
        return
      }
      const params = {
        chatIds: commentIds
      }
      interactionApi.getChatInteraction(params).then(resp => {
        if (resp.httpStatus === 200) {
          const interactionMap = new Map(resp.data.map(interaction => [interaction.chatId, interaction]))
          this.list = this.list.map(item => {
            if (!item.articleComment) {
              return item
            }
            const interactionData = interactionMap.get(item.articleComment.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              item.articleComment = {
                ...item.articleComment,
                interaction: interactionData
              }
            }
            return item
          })
          console.log('获取评论互动成功', this.list)
        }
      })
    }
  }
}
</script>

<style scoped>
.yingdi-index {
  min-height: 700px;
  padding-bottom: 100px;
  overflow-y: auto;
}

.post-msg {
  margin-left: 20px;
  font-weight: bold;
}

.post-msg span {
  margin-right: 20px;
}
</style>
